<div class="miles-editable miles-card padded" *ngIf="!isEditing && task.Id > 0" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
    <h4>
        Task Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <div class="display-padded"><b>Task ID: </b>{{ task.Id }}</div>
            <div class="display-padded" *ngIf="!task.DboAttachmentExtended?.InvestmentId"><b>Fund: </b>{{ task.Fund?.Name }}</div>
            <div class="display-padded" *ngIf="task.DboAttachmentExtended?.InvestmentId"><b>Tax Position: </b>{{ task.Investment?.Name }}</div>
            <div class="display-padded"><b>Document Type: </b>{{ task.DboTaskType.Name }}</div>
            <ng-container *ngIf="selectedDocType?.Id && selectedDocType?.Id === taskTypePartnersCapitalStatementId">
                <div class="display-padded"><strong> PCAP Audited: </strong>{{ IsPCAPAudited }}</div>
            </ng-container>
            <div class="display-padded"><b>Clients: </b>{{ clientAssigned }}</div>
            <div class="display-padded" *ngIf="showTaxFormYear(task)"><b>Tax Form Year: </b>{{ task.DboAttachmentExtended.TaxFormYear }}</div>
            <div class="display-padded" *ngIf="showDueDate(task)">
                <b>Due Date: </b>{{ task.DboAttachmentExtended.DateNecessary | date: 'M/d/yyyy':'UTC' }}
            </div>
            <div class="display-padded" *ngIf="showStatementDate(task)">
                <b>Statement Date: </b>{{ task.DboAttachmentExtended.StatementDate | date: 'M/d/yyyy':'UTC' }}
            </div>
            <div class="display-padded"><b>Task Created Date: </b>{{ task.CreatedOn | date: 'M/d/yyyy':'UTC' }}</div>
            <div class="display-padded"><b>Task Created By: </b>{{ task.CreatedByFullName }}</div>
            <div class="display-padded"><b>Mailing Received Date: </b>{{ task.ReceivedDate | date: 'M/d/yyyy':'UTC' }}</div>
            <div class="display-padded"><b>Attachment Password: </b>{{ task.DboAttachment.Password }}</div>
            <div class="display-padded"><b>Notes: </b>{{ task.DboAttachmentExtended.Notes }}</div>
            <div class="display-padded"><b>High Priority: </b>{{ isTaskHighPriority }}</div>
            <div class="display-padded"><b>Distribution Notice: </b>{{ IsDistributionNotice }}</div>
            <div class="display-padded"><b>Status: </b>{{task.TaskStatus.Name}}</div>
            <div class="display-padded">
                <b>Tags: </b>
                <span
                *ngFor="let tag of task.Tags; let i = index"
                class="tagItem"
                [style.background-color]="tag.Color"
                [style.width.%]="25"
                [style.color]="findColorContrast(tag.Color)"
                [title]="tag.Name">
                    <p class="tagName">{{ tag.Name }}</p>
                </span>
            </div>
            <div class="display-padded"><b>Task Last Updated On: </b>{{ task.LastUpdatedOn | date: 'yyyy-MM-dd HH:mm:ss.SSS' }}</div>
            <div class="display-padded"><b>Attachment Last Updated On: </b>{{ task.DboAttachmentExtended?.LastUpdatedOn | date: 'yyyy-MM-dd HH:mm:ss.SSS' }}</div>
        </div>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Task Info</h4>
    <div class="row">
        <div class="col-md-12">
            <form #taskForm="ngForm" (ngSubmit)="onSubmit(taskForm.value)">
                <div class="display-padded" *ngIf="!hasInvestmentId">
                    <label for="fund">Fund</label>
                    <ng-select
                        [items]="funds$ | async"
                        bindLabel="Name"
                        [closeOnSelect]="true"
                        [multiple]="false"
                        [hideSelected]="true"
                        [typeahead]="fundSubject$"
                        [(ngModel)]="fundsList"
                        name="fund"
                        (change)="onFundChange($event)"
                        [disabled]="disableFields"
                    >
                    </ng-select>
                </div>

                <div class="display-padded">
                    <label for="clients">Clients</label>
                    <ng-select
                        [items]="(clientSubject$ | async) ? (clients$ | async) : clients"
                        bindLabel="Name"
                        [closeOnSelect]="true"
                        [multiple]="true"
                        [hideSelected]="true"
                        [typeahead]="clientSubject$"
                        [(ngModel)]="clientsList"
                        [virtualScroll]="true"
                        name="clients"
                        [disabled]="disableFields"
                    >
                    </ng-select>
                </div>

                <div class="display-padded" *ngIf="hasInvestmentId">
                    <label for="investment">Tax Position</label>
                    <ng-select
                        [items]="investment$ | async"
                        bindLabel="Name"
                        [closeOnSelect]="true"
                        [multiple]="false"
                        [hideSelected]="true"
                        [typeahead]="investmentSubject$"
                        [(ngModel)]="investmentList"
                        name="investment"
                    >
                    </ng-select>
                </div>
                <div class="display-padded">
                    <label for="selectedDoc">Document Type</label>
                    <select
                        name="doctype"
                        [(ngModel)]="selectedDocType"
                        (ngModelChange)="onDocumentTypeChange($event)"
                        name="selectedDoc"
                        class="form-control"
                    >
                        <option class="form-control" *ngFor="let taskType of taskTypes" [ngValue]="taskType">{{ taskType.Name }}</option>
                    </select>
                </div>

                <ng-container *ngIf="selectedDocType?.Id && selectedDocType?.Id === taskTypePartnersCapitalStatementId">
                    <div class="display-padded">
                        <label for="isPcapAudited">Is PCAP Audited</label>
                        <input type="checkbox" name="isPcapAudited" [(ngModel)]="IsPCAPAudited" [checked]="IsPCAPAudited" />
                    </div>
                </ng-container>

                <div class="display-padded" *ngIf="showDueDate(task)">
                    <label for="dueDate">Due Date</label>
                    <input
                        type="date"
                        class="form-control"
                        name="dueDate"
                        [ngModel]="task.DboAttachmentExtended.DateNecessary | date: 'yyyy-MM-dd':'UTC'"
                        (ngModelChange)="task.DboAttachmentExtended.DateNecessary = $event"
                    />
                </div>
                <div class="display-padded" *ngIf="showStatementDate(task)">
                    <label for="statementDate">Statement Date</label>
                    <input
                        type="date"
                        class="form-control"
                        name="statementDate"
                        [ngModel]="task.DboAttachmentExtended.StatementDate | date: 'yyyy-MM-dd':'UTC'"
                        (ngModelChange)="task.DboAttachmentExtended.StatementDate = $event"
                    />
                </div>
                <div class="display-padded" *ngIf="showTaxFormYear(task)">
                    <label for="taxFormYear">Tax Form Year</label>
                    <input
                        type="text"
                        class="form-control"
                        name="taxFormYear"
                        maxlength="4"
                        [ngModel]="task.DboAttachmentExtended.TaxFormYear"
                        (ngModelChange)="task.DboAttachmentExtended.TaxFormYear = $event"
                    />
                </div>
                <div class="display-padded">
                    <label for="attachmentPass">Attachment Password</label>
                    <input type="text" class="form-control" name="attachmentPass" maxlength="100" [ngModel]="task.DboAttachment.Password" />
                </div>
                <div class="display-padded">
                    <label for="notes">Notes</label>
                    <textarea name="notes" class="form-control" [(ngModel)]="notes"></textarea>
                </div>
                <div class="display-padded">
                    <label>Is High Priority</label>
                    <input type="checkbox" name="isTaskHighPriority" [(ngModel)]="isTaskHighPriority" [checked]="isTaskHighPriority" />
                </div>
                <div class="display-padded">
                    <label>Distribution Notice</label>
                    <input type="checkbox" name="IsDistributionNotice" [(ngModel)]="IsDistributionNotice" [checked]="IsDistributionNotice" />
                </div>

                <div class="display-padded">
                    <label for="selectedTaskStatus">Status</label>
                    <select
                        name="status"
                        [(ngModel)]="selectedTaskStatus"
                        (ngModelChange)="onDocumentTypeChange($event)"
                        name="selectedTaskStatus"
                        class="form-control"
                        [disabled]="disableStatusField"
                    >
                        <option class="form-control" *ngFor="let status of taskStatuses" [ngValue]="status" [disabled]="isStatusOptionDisabled(status.Id)">{{ status.Name }}</option>
                    </select>
                </div>

                <div class="display-padded">
                    <label>Tags</label>
                    <ng-select
                        name="attachmentTag"
                        bindLabel="Name"
                        bindValue="Id"
                        [multiple]="true"
                        [items]="attachmentTag"
                        [(ngModel)]="selectedTagIds"
                        (change)="onSelectedTagChange()">
                    </ng-select>
                </div>

                <div class="display-padded">
                    <button type="submit" class="btn btn-flat btn-success" [disabled]="!funds?.length > 0">Save</button>

                    <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                </div>
                <br />
            </form>
        </div>
    </div>
    <div>
        <mt-modal-wrapper *ngIf="showModal" [options]="modalOptions">
            <div>
                <textarea [(ngModel)]="taskUpdateReason" cols="40" rows="10" required></textarea>
            </div>
            <br />
            <button type="button" class="btn btn-success" (click)="handleSave()">Agree</button>
            <button type="button" class="btn btn-default" (click)="toggleModal('')">Close</button>
        </mt-modal-wrapper>
    </div>
</div>
